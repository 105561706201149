import { useEffect, useState } from 'react';
import { ActionList, ActionMenu, Avatar, Header, IconButton, NavList, useTheme } from '@primer/react';
import { getCurrentUserUsername, hasPrivileges } from '../../services/CurrentUserService';
import { logout } from '../../api/api';
import md5 from 'md5';
import logo from '../../assets/cat-icon-white.svg';
import {
  AlertIcon,
  ChecklistIcon,
  CpuIcon,
  LocationIcon,
  OrganizationIcon,
  PeopleIcon,
  PersonIcon,
  RowsIcon,
  SignOutIcon,
  ThreeBarsIcon,
  ToolsIcon,
  TriangleDownIcon
} from '@primer/octicons-react';
import { useLocation } from 'react-router';
import NotificationsToggleSwitch from '../NotificationsToggleSwitch';
import { notificationsSupported } from '../../services/NotificationService';

function UserMenu() {
  const userEmail = getCurrentUserUsername() ? getCurrentUserUsername() : '';

  return (
    <Header.Item sx={{ cursor: 'pointer', mr: 0 }}>
      <ActionMenu>
        <ActionMenu.Anchor>
          <span>
            <Avatar
              src={'https://www.gravatar.com/avatar/' + md5(userEmail) + '?d=mp'}
              size={32}
              square
              aria-hidden={'true'}
              sx={{ marginTop: -1, marginBottom: -1 }}
            />
          </span>
        </ActionMenu.Anchor>
        <ActionMenu.Overlay>
          <NavList>
            {notificationsSupported() && (
              <ActionList.Item sx={{ lineHeight: '24px' }}>
                <span id="notifications">Teavitused</span>
                <ActionList.TrailingVisual>
                  <NotificationsToggleSwitch labelId="notifications" />
                </ActionList.TrailingVisual>
              </ActionList.Item>
            )}
            <NavList.Item href="/userinfo">
              <NavList.LeadingVisual>
                <PersonIcon />
              </NavList.LeadingVisual>
              Profiil
            </NavList.Item>
            <ActionList.Item onSelect={doLogout}>
              <ActionList.LeadingVisual>
                <SignOutIcon />
              </ActionList.LeadingVisual>
              Logi välja
            </ActionList.Item>
          </NavList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </Header.Item>
  );
}

function Reportsv2Items() {
  return (
    <>
      <NavList.Item href="/reports/v2/invoice">
        <NavList.LeadingVisual>
          <RowsIcon />
        </NavList.LeadingVisual>
        Arveread
      </NavList.Item>
      <NavList.Item href="/reports/v2/salary">
        <NavList.LeadingVisual>
          <PeopleIcon />
        </NavList.LeadingVisual>
        Töötunnid
      </NavList.Item>
    </>
  );
}

function DesktopHeaderItems() {
  return (
    <>
      <Header.Item>
        <Header.Link href="/">
          <img alt="Kiisu" src={logo} width="32" height="32" style={{ marginTop: -1, marginBottom: -1 }} />
        </Header.Link>
      </Header.Item>
      {hasPrivileges(['view_orders']) && (
        <Header.Item>
          <Header.Link href="/orders">Tellimused</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_customers']) && (
        <Header.Item>
          <Header.Link href="/customers">Kliendid</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_locations']) && (
        <Header.Item>
          <Header.Link href="/locations">Asukohad</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_spares']) && (
        <Header.Item>
          <Header.Link href="/spares">Varuosad</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_devices']) && (
        <Header.Item>
          <Header.Link href="/devices">Seadmed</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_alarms']) && (
        <Header.Item>
          <Header.Link href="/alarms">Alarmid</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_users']) && (
        <Header.Item>
          <Header.Link href="/users">Kasutajad</Header.Link>
        </Header.Item>
      )}
      {hasPrivileges(['view_reports']) && (
        <Header.Item>
          <ActionMenu>
            <ActionMenu.Anchor>
              <Header.Link>
                Raportid <TriangleDownIcon />
              </Header.Link>
            </ActionMenu.Anchor>
            <ActionMenu.Overlay>
              <ActionList>
                <Reportsv2Items />
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </Header.Item>
      )}
      <Header.Item full /* because of this "full" */></Header.Item>

      <UserMenu></UserMenu>
    </>
  );
}

function doLogout() {
  logout().then(() => {
    localStorage.clear();
    window.location.assign('/login');
  });
}

function MobileHeaderItems(props: any) {
  const location = useLocation();

  const pageNames = {
    '/orders': 'Tellimused',
    '/customers': 'Kliendid',
    '/locations': 'Asukohad',
    '/users': 'Kasutajad',
    '/spares': 'Varuosad',
    '/devices': 'Seadmed',
    '/alarms': 'Alarmid',
    '/reports/v2/invoice': 'Arveread',
    '/reports/v2/salary': 'Töötunnid',
    '/userinfo': 'Profiil'
  } as { [key: string]: string };

  return (
    <>
      <Header.Item>
        <ActionMenu>
          <ActionMenu.Anchor>
            <IconButton icon={ThreeBarsIcon} sx={{ background: 'transparent' }} aria-label="Open header menu" />
          </ActionMenu.Anchor>
          <ActionMenu.Overlay anchorSide="outside-left">
            <NavList>
              {hasPrivileges(['view_orders']) && (
                <NavList.Item href="/orders">
                  <NavList.LeadingVisual>
                    <ChecklistIcon />
                  </NavList.LeadingVisual>
                  Tellimused
                </NavList.Item>
              )}
              {hasPrivileges(['view_customers']) && (
                <NavList.Item href="/customers">
                  <NavList.LeadingVisual>
                    <OrganizationIcon />
                  </NavList.LeadingVisual>
                  Kliendid
                </NavList.Item>
              )}
              {hasPrivileges(['view_locations']) && (
                <NavList.Item href="/locations">
                  <NavList.LeadingVisual>
                    <LocationIcon />
                  </NavList.LeadingVisual>
                  Asukohad
                </NavList.Item>
              )}
              {hasPrivileges(['view_spares']) && (
                <NavList.Item href="/spares">
                  <NavList.LeadingVisual>
                    <ToolsIcon />
                  </NavList.LeadingVisual>
                  Varuosad
                </NavList.Item>
              )}
              {hasPrivileges(['view_users']) && (
                <NavList.Item href="/users">
                  <NavList.LeadingVisual>
                    <PeopleIcon />
                  </NavList.LeadingVisual>
                  Kasutajad
                </NavList.Item>
              )}
              {(hasPrivileges(['view_devices']) || hasPrivileges(['view_alarms'])) && (
                <>
                  <NavList.Group title="Automaatika">
                    {hasPrivileges(['view_devices']) && (
                      <NavList.Item href="/devices">
                        <NavList.LeadingVisual>
                          <CpuIcon />
                        </NavList.LeadingVisual>
                        Seadmed
                      </NavList.Item>
                    )}
                    {hasPrivileges(['view_alarms']) && (
                      <NavList.Item href="/alarms">
                        <NavList.LeadingVisual>
                          <AlertIcon />
                        </NavList.LeadingVisual>
                        Alarmid
                      </NavList.Item>
                    )}
                  </NavList.Group>
                </>
              )}
              {hasPrivileges(['view_reports']) && (
                <>
                  <NavList.Group title="Raportid">
                    <Reportsv2Items />
                  </NavList.Group>
                </>
              )}
            </NavList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </Header.Item>

      <Header.Item>
        <Header.Link href="/">
          <img alt="Kiisu" src={logo} width="32" height="32" style={{ marginTop: -1, marginBottom: -1 }} />
        </Header.Link>
      </Header.Item>
      <Header.Item full>
        <Header.Link to={location.pathname}>{pageNames[location.pathname]}</Header.Link>
      </Header.Item>

      <UserMenu></UserMenu>
    </>
  );
}

function MyHeader() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { theme } = useTheme();
  const breakPointLg = parseInt(theme!.breakpoints[2], 10);

  const detectSize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);

    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowWidth]);

  return (
    <Header sx={{ paddingTop: 'calc(16px + env(safe-area-inset-top))' }}>
      {windowWidth > breakPointLg ? <DesktopHeaderItems /> : <MobileHeaderItems />}
    </Header>
  );
}

export default MyHeader;
