import { NotificationsAPI } from 'kiisu-api-types/notifications';
import { createRestypedAxios } from './base.api';

const notificationsAPI = createRestypedAxios<NotificationsAPI>();

export function getServerKey() {
  return notificationsAPI.get<'/notifications/v1/server-key'>('/notifications/v1/server-key');
}

export function getSubscription(endpoint: string) {
  return notificationsAPI.get<'/notifications/v1/subscriptions/:endpoint'>(
    `/notifications/v1/subscriptions/${encodeURIComponent(endpoint)}`
  );
}

export function addSubscription(subscription: PushSubscriptionJSON) {
  return notificationsAPI.post<'/notifications/v1/subscriptions'>('/notifications/v1/subscriptions', subscription);
}

export function patchSubscription(endpoint: string) {
  return notificationsAPI.patch<'/notifications/v1/subscriptions/:endpoint'>(
    `/notifications/v1/subscriptions/${encodeURIComponent(endpoint)}`
  );
}

export function removeSubscription(endpoint: string) {
  return notificationsAPI.delete<'/notifications/v1/subscriptions/:endpoint'>(
    `/notifications/v1/subscriptions/${encodeURIComponent(endpoint)}`
  );
}
