import {
  addSubscription,
  getServerKey,
  getSubscription,
  patchSubscription,
  removeSubscription
} from '../api/api.notifications';

export const notificationsSupported = (): boolean => {
  return 'serviceWorker' in navigator && 'Notification' in window;
};

export const hasPermission = (): boolean => {
  return notificationsSupported() && Notification.permission === 'granted';
};

export const requestPermission = async () => {
  if ('Notification' in window) {
    return await Notification.requestPermission();
  }
};

export const getServerApplicationKey = async (): Promise<string | undefined> => {
  const response = await getServerKey();
  return response.data;
};

export const initNotifications = async (): Promise<boolean> => {
  if (!hasPermission()) {
    const permission = await requestPermission();
    if (permission !== 'granted') {
      return false;
    }
  }

  return await checkExistingSubscription();
};

export const checkExistingSubscription = async (): Promise<boolean> => {
  const subscription = await getPushManagerSubscription();

  // If subscription exists on browser, check if registered on server side, otherwise add
  if (subscription) {
    const storedSubscription = await getSubscription(subscription.endpoint).then((resp) => resp.data);
    if (storedSubscription) {
      await patchSubscription(subscription.endpoint);
    } else {
      await addSubscription(subscription.toJSON());
    }
    return true;
  }

  return false;
};

export const getPushManagerSubscription = async () => {
  return await navigator.serviceWorker.ready.then(async (registration) => {
    return await registration.pushManager.getSubscription();
  });
};

export const subscribe = async (serverApplicationKey: string | undefined) => {
  if (!serverApplicationKey) {
    console.error('No server side application key received!');
    return;
  }
  if (!hasPermission()) {
    console.error('Notifications have been blocked!');
    return;
  }

  const registration = await navigator.serviceWorker.ready;
  const subscription = await registration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: serverApplicationKey
  });
  console.log('Subscribed to web push service ', subscription.toJSON());
  await addSubscription(subscription.toJSON());

  return subscription;
};

export const unsubscribe = async () => {
  const subscription = await getPushManagerSubscription();
  if (subscription) {
    const success = await subscription.unsubscribe();
    if (!success) {
      console.error('Unsubscription from web push service failed!!');
    }
    console.log('Unsubscribed from web push service.');
    await removeSubscription(subscription.endpoint);
  }
  return subscription;
};
