import { CoreAPI } from 'kiisu-api-types/core';
import { ReportQuery } from 'kiisu-api-types/core.reports';
import { createRestypedAxios } from './base.api';

const coreAPI = createRestypedAxios<CoreAPI>();

export function getInvoiceReport(params: { customerId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/invoice'>('/core/v2/reports/invoice', {
    params: params
  });
}

export function getSalaryReport(params: { userId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/salary'>('/core/v2/reports/salary', {
    params: params
  });
}

export function getReportCSVFile(type: 'invoice' | 'salary', params: { customerId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/csv/:type'>(`/core/v2/reports/csv/${type}`, {
    params: params,
    responseType: 'blob'
  });
}
