import { CoreAPI } from 'kiisu-api-types/core';
import { MarkupNew, SpareGroupNew, SpareNew, SpareStored } from 'kiisu-api-types/core.spares';
import { createRestypedAxios } from './base.api';

const coreAPI = createRestypedAxios<CoreAPI>();

export function getMarkups() {
  return coreAPI.get<'/core/v2/markups'>('/core/v2/markups');
}

export function postMarkup(data: MarkupNew) {
  return coreAPI.post<'/core/v2/markups'>('/core/v2/markups', data);
}

export function getSpareGroups() {
  return coreAPI.get<'/core/v2/spare-groups'>('/core/v2/spare-groups');
}

export function postSpareGroup(data: SpareGroupNew) {
  return coreAPI.post<'/core/v2/spare-groups'>('/core/v2/spare-groups', data);
}

export function getSpares(groupId?: string) {
  return coreAPI.get<'/core/v2/spares'>('/core/v2/spares', {
    params: {
      groupId: groupId
    }
  });
}

export function postSpare(data: SpareNew) {
  return coreAPI.post<'/core/v2/spares'>('/core/v2/spares', data);
}

export function getSpare(id: string) {
  return coreAPI.get<'/core/v2/spares/:id'>(`/core/v2/spares/${id}`);
}

export function patchSpare(spare: SpareStored) {
  return coreAPI.patch<'/core/v2/spares/:id'>(`/core/v2/spares/${spare.metadata.id}`, spare);
}
