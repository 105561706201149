import axios from 'axios';
import restTypedAxios from 'restyped-axios';

export const createAxios = (baseURL: string) => {
  const newInstance = axios.create({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  newInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('apptoken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  });

  return newInstance;
};

export const createRestypedAxios = <T>(baseURL?: string) => {
  const newInstance = restTypedAxios.create<T>({
    baseURL: baseURL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  newInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('apptoken');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  });

  return newInstance;
};
